import { ref } from "vue";
import axios from 'axios';
import { Question } from "@/types/question"
import { QusitionState } from "@/state/QusitionState";
import { QuestionRepository } from '@/repositories/QuestionRepository'
import { Category } from '@/types/category'

export const QuestionListLogic = () => {
    const listErrors = ref("");
    const listLoading = ref(false)
    const noMore = ref(false);
    // let questions = ref([]);
    const keyword = ref("");
    const filterFavorite = ref(false)
    const filterNewer = ref(false)

    const sortItems = [
        { value: 'body_asc', label: 'タイトル昇順' },
        { value: 'body_desc', label: 'タイトル降順' },
        { value: 'updated_at_desc', label: '更新順' },
        { value: 'created_at_desc', label: '新着順' },
    ];
    const sort = ref('body_asc');

    const loadQuestionsWithQuestionCategory = (question_category: Category) => {
        if (question_category.id == QusitionState.state.questionCategoryId) {
            return;
        }
        QusitionState.setQuestionCategory(question_category.id);
        QusitionState.set(null);
        loadQuestions(true);
    }
    const loadQuestions = async (clear = false, save_result = false) => {
        listLoading.value = true;
        if (clear) {
            QusitionState.state.questions = [];
            noMore.value = false;
            // QusitionState.setQuestionCategory(0);
        }
        const params: { [key: string]: string | number } = {}

        if (QusitionState.state.questionCategoryId > 0) {
            params.question_category_id = QusitionState.state.questionCategoryId
        }
        if (QusitionState.state.questions.length > 0) {
            params.offset = QusitionState.state.questions.length;
        }
        if (QusitionState.state.questions.length > 0) {
            const last_id = QusitionState.state.questions[QusitionState.state.questions.length - 1].id;
            params.last_id = last_id;
        }
        if (keyword.value.length > 0) {
            params.keyword = keyword.value;
        }
        if (filterFavorite.value == true) {
            params.favorite = "1";
        }
        if (filterNewer.value == true) {
            params.newer = "1";
        }
        if (save_result == true || QusitionState.state.questionCategoryId == 0) {
            params.save_result = 1
        }
        params.sort = sort.value;
        const repository: QuestionRepository = new QuestionRepository(QusitionState.state.projectId);
        try {
            const questions: Question[] = await repository.getList(params);
            if (questions.length == 0) {
                noMore.value = true;
            }
            QusitionState.addQuestions(questions)
        } catch (e: unknown) {
            if (axios.isAxiosError(e) && e.response) {
                listErrors.value = `QAのロード時にエラーが発生しました。(status: ${e.response.status})`;
            }

        }
        listLoading.value = false;

    };

    const questionListStateReturn = () => {
        return {
            loadQuestions,
            loadQuestionsWithQuestionCategory,
            listLoading,
            noMore,
            keyword,
            listErrors,
            filterFavorite,
            filterNewer,
            sort,
            sortItems,
        }
    }
    return {
        questionListStateReturn,
        ...questionListStateReturn()
    }
}


